<template>
    <div>
        <Loader v-if="signLoading" />

        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="showSignButton"
            :callback="signDocument"
            :disabled="signLoading"
            :spinner="signLoading"
        >
            <i class="fa fa-save"></i>
            {{ $t("APPLICATION.VIEW.SIGN_DOCUMENT") }}
        </st-button>

        <confirm-sign-document-modal-applicant
            :applicationId="applicationId"
            ref="confirm-sign-document-modal-applicant"
            @applicantSigned="applicantSigned"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmSignDocumentModalApplicant from "./ConfirmSignDocumentModalApplicant";
import Message from "@/shared/message/message";

export default {
    name: "ApplicationViewToolbarApplicant",
    components: {
        ConfirmSignDocumentModalApplicant,
    },
    data() {
        return {
            signLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            currentUser: "auth/currentUser",
        }),
        showSignButton() {
            if (this.record?.locked || !this.record?.to_sign_by_applicant_user) {
                return false;
            }
            return true;
        },
        applicationId() {
            return this.record?.id || '';
        },
    },
    methods: {
        ...mapActions({
            doFind: "applications/form/find",
            signDocumentApplicantUser: "applications/form/signDocumentApplicantUser",
        }),
        signDocument() {
            this.signLoading = true;

            this.signDocumentApplicantUser({ id: this.$route.params.id}).then(() => {
                this.$refs['confirm-sign-document-modal-applicant'].show();
                this.signLoading = false;
            }).catch(() => {
                this.signLoading = false;
                Message.error('APPLICATION.VIEW.SIGN_DOCUMENT_ERROR');
            });
        },
        async applicantSigned() {
            await this.doFind({id: this.$route.params.id});
        }
    },
};
</script>
